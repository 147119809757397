import { FacetFilter, FacetFilterType, useFacetsFilters, useFeatureFlags } from '@/app/composable';
import { computed } from '@vue/composition-api';
import { WorkflowExecutionStatus } from '../constants';
import { IndexedHarvesterSourceType } from '../types';

export function useDataCheckinFilters(get: any) {
    const { reducedFacetsFilters, defaultValue } = useFacetsFilters();
    const { isEnabled, areAnyEnabled } = useFeatureFlags();

    const status = {
        label: 'Status',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'status'),
        options: [
            { value: WorkflowExecutionStatus.Completed, label: 'Completed' },
            { value: WorkflowExecutionStatus.Failed, label: 'Failed' },
            { value: WorkflowExecutionStatus.Draft, label: 'Configuration' },
            { value: WorkflowExecutionStatus.Ready, label: 'Execution Pending' },
            { value: WorkflowExecutionStatus.Running, label: 'Running' },
            { value: WorkflowExecutionStatus.Suspended, label: 'Suspended' },
            { value: WorkflowExecutionStatus.Queued, label: 'Queued' },
            { value: WorkflowExecutionStatus.Updating, label: 'Update' },
            { value: WorkflowExecutionStatus.Cancelled, label: 'Cancelled' },
        ],
    };

    const schedulingOption = {
        label: 'Scheduling Option',
        type: FacetFilterType.Checkbox,
        default: defaultValue(get, 'schedulingOption'),
        options: [
            { value: 'realTime', label: 'Real-Time' },
            { value: 'active', label: 'Active Schedule' },
            { value: 'expired', label: 'Schedule Expired' },
            { value: 'future', label: 'Future Execution' },
            { value: 'no', label: 'No Schedule' },
        ],
        tooltip:
            "Filter the data check-in pipelines for which an explicit schedule has been set. Only pipelines with Harvesting Option: Large Files, Data Provider API, Platform Kafka, Data Provider's Kafka, Platform MQTT, Data Provider's MQTT, Data Provider's BigQuery, Data Provider's SQL are displayed. The dates, not the exact times, of the schedules are considered.",
    };

    const step = computed(() => {
        const options = [
            { value: 'mapping', label: 'Mapping' },
            { value: 'cleaning', label: 'Cleaning' },
        ];
        if (isEnabled('anonymisation')) options.push({ value: 'anonymisation', label: 'Anonymisation' });
        if (isEnabled('encryption')) options.push({ value: 'encryption', label: 'Encryption' });

        return { label: 'With Step', type: FacetFilterType.Checkbox, default: defaultValue(get, 'step'), options };
    });

    const harvestingOption = computed(() => {
        const options = [{ value: IndexedHarvesterSourceType.File, label: 'File' }];
        if (isEnabled('harvester.large-files'))
            options.push({ value: IndexedHarvesterSourceType.LargeFiles, label: 'Large Files' });
        if (isEnabled('harvester.api'))
            options.push({ value: IndexedHarvesterSourceType.Api, label: 'Data Provider API' });
        if (isEnabled('harvester.platform-api'))
            options.push({ value: IndexedHarvesterSourceType.InternalApi, label: 'Platform API' });
        if (isEnabled('harvester.platform-kafka'))
            options.push({ value: IndexedHarvesterSourceType.Kafka, label: 'Platform Kafka' });
        if (isEnabled('harvester.kafka'))
            options.push({ value: IndexedHarvesterSourceType.ExternalKafka, label: `Data Provider's Kafka` });
        if (isEnabled('harvester.platform-mqtt'))
            options.push({ value: IndexedHarvesterSourceType.MQTT, label: 'Platform MQTT' });
        if (isEnabled('harvester.mqtt'))
            options.push({ value: IndexedHarvesterSourceType.ExternalMQTT, label: `Data Provider's MQTT` });
        if (isEnabled('harvester.big-query'))
            options.push({ value: IndexedHarvesterSourceType.BigQuery, label: `Data Provider's BigQuery` });
        if (isEnabled('harvester.sql'))
            options.push({ value: IndexedHarvesterSourceType.SQL, label: `Data Provider's SQL` });
        if (isEnabled('harvester.sap-hana'))
            options.push({ value: IndexedHarvesterSourceType.SAPHana, label: `Data Provider's SAP Hana` });

        return {
            label: 'Harvesting Option',
            type: FacetFilterType.Checkbox,
            default: defaultValue(get, 'harvestingOption'),
            options,
        };
    });

    const location = computed(() => {
        const options = [{ value: 'cloud', label: 'Cloud' }];
        if (isEnabled('on-premise')) options.push({ value: 'onPremise', label: 'On-Premise' });

        return {
            label: 'Execution Location',
            type: FacetFilterType.Checkbox,
            default: defaultValue(get, 'location'),
            options,
        };
    });

    const scheduleHorizon = {
        label: 'Schedule Horizon',
        type: FacetFilterType.Date,
        default: {
            min: defaultValue(get, 'scheduleHorizonMin', false),
            max: defaultValue(get, 'scheduleHorizonMax', false),
        },
        tooltip: 'The time period within there are active schedules',
    };

    const dateCreated = {
        label: 'Date Created Range',
        type: FacetFilterType.Date,
        default: {
            min: defaultValue(get, 'dateCreatedMin', false),
            max: defaultValue(get, 'dateCreatedMax', false),
        },
    };

    const dateUpdated = {
        label: 'Date Updated Range',
        type: FacetFilterType.Date,
        default: {
            min: defaultValue(get, 'dateUpdatedMin', false),
            max: defaultValue(get, 'dateUpdatedMax', false),
        },
    };

    const user = {
        // displayed only to "shared" pipelines tab
        label: 'User',
        type: FacetFilterType.Treeselect,
        placeholder: 'Select user',
        default: defaultValue(get, 'user'),
        options: [],
    };

    const pipeline = {
        label: 'Pipeline ID',
        placeholder: 'Enter pipeline ID',
        type: FacetFilterType.Text,
        default: { id: defaultValue(get, 'pipeline', false) },
    };

    const dataCheckinFilters = computed(
        (): Record<string, FacetFilter> => {
            const all = {
                pipeline,
                status,
                step: step.value,
                harvestingOption: harvestingOption.value,
                location: location.value,
                dateCreated,
                dateUpdated,
                user,
            };
            if (
                areAnyEnabled([
                    'harvester.api',
                    'harvester.kafka',
                    'harvester.platform-kafka',
                    'harvester.mqtt',
                    'harvester.platform-mqtt',
                    'harvester.large-files',
                    'harvester.big-query',
                    'harvester.sql',
                ])
            )
                all['schedulingOption'] = schedulingOption;

            if (
                areAnyEnabled([
                    'harvester.api',
                    'harvester.kafka',
                    'harvester.platform-kafka',
                    'harvester.large-files',
                    'harvester.big-query',
                    'harvester.sql',
                ])
            )
                all['scheduleHorizon'] = scheduleHorizon;

            return all;
        },
    );

    const reducedDataCheckinFilters: any = reducedFacetsFilters(dataCheckinFilters);

    return { dataCheckinFilters, reducedDataCheckinFilters };
}
