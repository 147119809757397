import { useAxios } from '@vue-composable/axios';
import { Ref } from '@vue/composition-api';
import * as R from 'ramda';
import { PredictorAPI } from '../api';
import { FieldConfiguration, MappingConfiguration } from '../types';

export function useMappingPrediction(
    configuration: Ref<MappingConfiguration | undefined>,
    basePath: Ref<string[] | undefined>,
) {
    const { loading, exec, cancel } = useAxios(true);

    /**
     * Prepares the payload to be used with the predictor backend
     * @param fields The list of the selected fields
     * @param domain The domain id for this mapping
     * @param standard The standard (if any) the data conforms to
     * @param concept The concept id these fields belong to
     */
    const getPredictionPayload = (
        fields: Readonly<FieldConfiguration[]>,
        domain: number,
        standard: any,
        concept: number,
        targetIds: number[],
    ) => ({
        metadata: {
            domain,
            standard,
            concept,
        },
        fields: fields.map((field: FieldConfiguration) => ({
            ...R.omit(['sample', 'path'], field.source),
            path: getSourcePath(field.source.path),
            targetPath: field.target.path,
        })),
        targetIds,
    });

    const getSourcePath = (path: string[]) => {
        if (basePath?.value?.length && path.join('.').replaceAll('[]', '').startsWith(basePath.value.join('.')))
            return path.map((item: string, idx: number) => {
                const clearItem = item.replaceAll('[]', '');
                if (basePath?.value && idx < basePath.value.length && basePath.value[idx] === clearItem)
                    return clearItem;
                return item;
            });
        return path;
    };

    const predict = async (
        fields: FieldConfiguration[],
        conceptId: number | null | undefined,
        domainId?: number,
        targetIds: number[] = [],
    ) => {
        if (!configuration?.value?.domain) throw Error('No domain defined');
        if (!conceptId) throw Error('No concept defined');

        const domain = !R.isNil(domainId) ? domainId : configuration.value.domain.id;
        const payload = getPredictionPayload(fields, domain, configuration.value.standard, conceptId, targetIds);

        const predictionResponse = await exec(PredictorAPI.predict(payload));
        return predictionResponse?.data;
    };

    return { loading, predict, cancel };
}
