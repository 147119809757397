import { clone, is, isNil } from 'ramda';
import {
    BooleanAnonymisationField,
    BooleanGroupRule,
    DateAnonymisationField,
    GeneralisationMethod,
    IntervalRule,
    MaskingAnonymisationField,
    MaskingRule,
    NumericLevelAnonymisationField,
    NumericalGroupRule,
    QuasiIdentifierAnonymisationField,
} from '../types';
import { MappingFieldConfiguration } from '../types/typings';
import { Ref, computed } from '@vue/composition-api';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

type AnonymisationLevel = { label: string; from: string; to: string }[] | number;
type RuleCalculatorFunction = () => any[];

dayjs.extend(utc);

export function useQuasiIdentifierAnonymisation(
    field: Ref<QuasiIdentifierAnonymisationField>,
    mappingField: Ref<MappingFieldConfiguration & { originalTitle: string }>,
    sample: Ref<Array<unknown>>,
    maxAutoLevels = 3,
) {
    const dateTypeGeneralisationInformation: Record<
        'date' | 'datetime' | 'time',
        {
            format: string;
            levelOptions: { level: number; label: string }[];
            rules: Record<number, { label: string; dateFunc: Function }>;
            description: string;
        }
    > = {
        date: {
            format: `YYYY-MM-DD`,
            levelOptions: [
                {
                    level: 2,
                    label: 'Month',
                },
                {
                    level: 3,
                    label: 'Year (Decade)',
                },
            ],
            rules: {
                1: { label: 'Day', dateFunc: (d: dayjs.Dayjs) => d.set('date', 1) },
                2: { label: 'Month and day', dateFunc: (d: dayjs.Dayjs) => d.set('month', 0) },
                3: { label: 'All parts', dateFunc: (d: dayjs.Dayjs) => d.set('year', d.year() - (d.year() % 10)) },
            },
            description: `The field values will likely be generalized by resetting different parts of the date, starting
                from the day until the year. The anonymisation algorithm will try to reset as few datetime parts
                as possible.`,
        },
        datetime: {
            format: `YYYY-MM-DDTHH:mm:ss UTC`,
            levelOptions: [
                {
                    level: 3,
                    label: 'Hours',
                },
                {
                    level: 4,
                    label: 'Day',
                },
                {
                    level: 5,
                    label: 'Month',
                },
                {
                    level: 6,
                    label: 'Year (Decade)',
                },
            ],
            rules: {
                1: { label: 'Seconds', dateFunc: (d: dayjs.Dayjs) => d.set('second', 0).set('millisecond', 0) },
                2: { label: 'Minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('minute', 0) },
                3: { label: 'Hours, minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('hour', 0) },
                4: { label: 'Day, hours, minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('date', 1) },
                5: {
                    label: 'Month, day, hours, minutes and seconds',
                    dateFunc: (d: dayjs.Dayjs) => d.set('month', 0),
                },
                6: { label: 'All parts', dateFunc: (d: dayjs.Dayjs) => d.set('year', d.year() - (d.year() % 10)) },
            },
            description: `The field values will likely be generalized by resetting different parts of the datetime,
                starting from the seconds until the year. The anonymisation algorithm will try to reset as few
                datetime parts as possible.`,
        },
        time: {
            format: `HH:mm:ss UTC`,
            levelOptions: [
                {
                    level: 2,
                    label: 'Minutes',
                },
                {
                    level: 3,
                    label: 'Hours',
                },
            ],
            rules: {
                1: { label: 'Seconds', dateFunc: (d: dayjs.Dayjs) => d.set('second', 0).set('millisecond', 0) },
                2: { label: 'Minutes and seconds', dateFunc: (d: dayjs.Dayjs) => d.set('minute', 0) },
                3: { label: 'All parts', dateFunc: (d: dayjs.Dayjs) => d.set('year', d.year() - (d.year() % 10)) },
            },
            description: `The field values will likely be generalized by resetting different parts of the time, starting
                from the seconds until the hours. The anonymisation algorithm will try to reset as few time
                parts as possible.`,
        },
    };

    const ruleCalculator: Record<GeneralisationMethod, RuleCalculatorFunction> = {
        masking: () => {
            const r: MaskingRule[] = [];

            const levelCount = getLevelCount();
            let level: number | undefined = undefined;
            for (let i = 1; i <= levelCount; i += 1) {
                level = getNextLevel(i, level) as number;
                if (i === 1) {
                    r.push({
                        level: i,
                        charactersMessage: 'The',
                        charactersValue: 'last character',
                        replaceMessage: 'of the values will be replaced with the masking character',
                        replaceValue: (field.value as MaskingAnonymisationField).options.maskingChar,
                    });
                } else if (i === maxStringLength.value) {
                    r.push({
                        level: i,
                        charactersMessage: '',
                        charactersValue: 'All characters',
                        replaceMessage: 'of the values will be replaced with the masking character',
                        replaceValue: (field.value as MaskingAnonymisationField).options.maskingChar,
                    });
                } else
                    r.push({
                        level: i,
                        charactersMessage: 'The',
                        charactersValue: `last ${i} characters`,
                        replaceMessage: 'of the values will be replaced with the masking character',
                        replaceValue: (field.value as MaskingAnonymisationField).options.maskingChar,
                    });
            }
            return r;
        },
        interval: () => {
            const r: IntervalRule[] = [];
            let level: { interval: number } | undefined = undefined;
            for (let i = 1; i <= getLevelCount(); i += 1) {
                level = getNextLevel(i, level) as { interval: number };
                r.push({
                    level: i,
                    message: 'Values will be generalized to arithmetic intervals of size',
                    interval: level.interval,
                });
            }

            return r;
        },
        ['numerical-group']: () => {
            const r: NumericalGroupRule[] = [];
            let level: { label: string | null; from: number; to: number }[] = [];
            for (let i = 1; i <= getLevelCount(); i += 1) {
                level = getNextLevel(i, level) as { label: string | null; from: number; to: number }[];
                const rule: any = {
                    level: i,
                    message: 'Values will be generalized to numerical groups as follows:',
                    groupRules: [],
                };
                for (let g = 0; g < level.length; g++) {
                    const group = level[g];
                    rule.groupRules.push({
                        fromMessage: 'Values from',
                        fromValue: group.from,
                        toMessage: 'to',
                        toValue: group.to,
                        labelMessage: 'will be replaced with the label',
                        labelValue: !isNil(group.label) ? group.label : `${group.from}:${group.to}`,
                    });
                }
                r.push(rule);
            }

            return r;
        },
        ['boolean-group']: () => {
            const r: BooleanGroupRule[] = [];

            const levelCount = getLevelCount();

            let level: number | undefined = undefined;
            for (let i = 1; i <= levelCount; i += 1) {
                level = getNextLevel(i, level) as number;
                if ((field.value as BooleanAnonymisationField).options.show)
                    r.push({
                        level: i,
                        beforeActionMessage: 'The boolean values will be',
                        action: 'grouped together',
                        afterActionMessage: '',
                    });
                else
                    r.push({
                        level: i,
                        beforeActionMessage: 'The column will be',
                        action: 'dropped',
                        afterActionMessage: 'from the dataset',
                    });
            }
            return r;
        },
        ['datetime']: () => {
            const r: {
                level: number;
                resetParts: string;
                beforeResetMessage: string;
                afterResetMessage: string;
            }[] = [];

            const levelCount = getLevelCount();

            let level: number | undefined = undefined;
            for (let i = 1; i <= levelCount; i += 1) {
                level = getNextLevel(i, level) as number;

                r.push({
                    level: i,
                    resetParts: dateTypeGeneralisationInformation[field.value.generalisation].rules[level].label,
                    beforeResetMessage: 'will be',
                    afterResetMessage: 'from the datetime values',
                });
            }

            return r;
        },
        ['date']: () => {
            const r: {
                level: number;
                resetParts: string;
                beforeResetMessage: string;
                afterResetMessage: string;
            }[] = [];

            const levelCount = getLevelCount();

            let level: number | undefined = undefined;
            for (let i = 1; i <= levelCount; i += 1) {
                level = getNextLevel(i, level) as number;

                r.push({
                    level: i,
                    resetParts: dateTypeGeneralisationInformation[field.value.generalisation].rules[level].label,
                    beforeResetMessage: 'will be',
                    afterResetMessage: 'from the date values',
                });
            }

            return r;
        },
        ['time']: () => {
            const r: {
                level: number;
                resetParts: string;
                beforeResetMessage: string;
                afterResetMessage: string;
            }[] = [];

            const levelCount = getLevelCount();

            let level: number | undefined = undefined;
            for (let i = 1; i <= levelCount; i += 1) {
                level = getNextLevel(i, level) as number;

                r.push({
                    level: i,
                    resetParts: dateTypeGeneralisationInformation[field.value.generalisation].rules[level].label,
                    beforeResetMessage: 'will be',
                    afterResetMessage: 'from the date values',
                });
            }

            return r;
        },
    };

    const rules = computed(() => {
        return !isNil(field.value.generalisation) ? ruleCalculator[field.value.generalisation]() : [];
    });

    // Checks if a field has automated leveling
    const hasAutoLeveling = computed(
        () =>
            (field.value.generalisation === 'interval' || field.value.generalisation === 'numerical-group') &&
            (field.value as NumericLevelAnonymisationField).options.leveling === 'auto',
    );

    // Returns the length of the largest string in sample
    const maxStringLength = computed((): number =>
        field.value.type === 'string' && sample
            ? sample.value.reduce((acc: number, row: unknown): number => {
                  let value: string | string[] = getSampleValue(row) as string;
                  if (isNil(value)) return acc;
                  if (!is(Array, value)) value = [value];

                  return (value as string[]).reduce((acc2: number, item: string | undefined): number => {
                      return !isNil(item) && item.length > acc2 ? item.length : acc2;
                  }, acc);
              }, 0)
            : 0,
    );

    const replaceNullValue = (v: any) => {
        if (!field.value.options.nullValues.keep && isNil(v)) {
            return field.value.options.nullValues.replaceWith;
        }
        return v;
    };

    const getSampleValueIterative = (row: any, path: string[]): string | number | string[] | number[] => {
        if (!mappingField) throw Error('Mapping field is not defined');
        if (path.length === 0) {
            const value = replaceNullValue(
                mappingField.value.multiple
                    ? row[mappingField.value.originalTitle][0]
                    : row[mappingField.value.originalTitle],
            );
            if (mappingField.value.type === 'time') return `1970-01-01T${value}Z`; // add date to the time to be parsed by dayjs
            if (mappingField.value.type === 'date') return `${value}T00:00:00Z`; // add a time to the date to be parsed by dayjs
            return value;
        }

        const keyInFocusRaw: string = path.shift() as string;
        const splitKeyInFocus = keyInFocusRaw.split('[]');
        const isArrayData = splitKeyInFocus.length > 1;
        const keyInFocus = splitKeyInFocus[0];
        if (isArrayData) return getSampleValueIterative(row[keyInFocus][0], path);

        return getSampleValueIterative(row[keyInFocus], path);
    };

    const getSampleValue = (row: any) =>
        mappingField ? getSampleValueIterative(row, clone(mappingField.value.path)) : undefined;

    // Returns the number of levels of a field
    const getLevelCount = (): number => {
        switch (field.value.generalisation) {
            case 'masking':
                return maxStringLength.value;
            case 'datetime':
            case 'date':
            case 'time':
                return (field.value as DateAnonymisationField).options.finalLevel;
            case 'boolean-group':
                return 1;
            case 'interval':
            case 'numerical-group':
                return (field.value as NumericLevelAnonymisationField).options.leveling === 'auto'
                    ? maxAutoLevels
                    : (field.value as NumericLevelAnonymisationField).options.levels.length;
            default:
                return 0;
        }
    };

    const mergeNumericalGroups = (level: AnonymisationLevel) => {
        if (!is(Array, level)) return level;
        const newLevel = [];
        if (level.length === 1) {
            newLevel.push(clone(level));
        }
        let i = 0;
        while (i < level.length) {
            if (level[i + 1]) {
                let label = null;
                if (level[i].label && level[i].label.length > 0) {
                    label = `${level[i].label},${level[i + 1].label}`;
                }
                newLevel.push({
                    from: level[i].from,
                    to: level[i + 1].to,
                    label,
                });
            } else {
                newLevel.push(clone(level[i]));
            }
            i += 2;
        }
        return newLevel;
    };

    // Returns a level based on the level index.
    const getNextLevel = (
        levelIndex: number,
        previousLevel: { label: string | null; from: number; to: number }[] | { interval: number } | number | undefined,
    ): { label: string | null; from: number; to: number }[] | { interval: number } | number => {
        let level: any = levelIndex;
        // If leveling options is 'auto', calculate the next level based on the previous level.
        if (hasAutoLeveling.value) {
            level = previousLevel;
            if (field.value.generalisation === 'interval' && levelIndex > 1) {
                level.interval *= 2;
            } else if (field.value.generalisation === 'numerical-group' && levelIndex > 1) {
                if (level.length > 1) {
                    level = mergeNumericalGroups(level);
                }
            } else if (field.value.generalisation === 'interval' || field.value.generalisation === 'numerical-group') {
                level = clone((field.value as NumericLevelAnonymisationField).options.levels[0]);
            }
        }
        // If leveling option is 'custom', get the level based on the level index.
        if (field.value.generalisation === 'interval' || field.value.generalisation === 'numerical-group') {
            if (!hasAutoLeveling.value)
                level = (field.value as NumericLevelAnonymisationField).options.levels[levelIndex - 1];
        }
        return level;
    };

    const baseRule = computed(() => {
        if (!mappingField) throw Error('Mapping field is not defined');

        return {
            level: 0,
            beforeTitleMessage: 'The initial values of',
            title: mappingField.value.title,
            afterTitleMessage: 'before anonymisation',
        };
    });

    return {
        getLevelCount,
        getNextLevel,
        getSampleValue,
        maxStringLength,
        baseRule,
        dateTypeGeneralisationInformation,
        rules,
    };
}
