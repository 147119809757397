import { Ref, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import { MultipleOperator } from '../constants';
import { Constraint, ConstraintType, OutliersRuleType, CleaningFieldConfiguration } from '../types/cleaning.type';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'ramda';

dayjs.extend(utc);

export function useCleaning(types: Ref<string[]> = ref<string[]>([]), fields: CleaningFieldConfiguration[] = []) {
    const getConditionText = (condition: string) => {
        switch (condition) {
            case 'EQUAL':
                return 'equal to';
            case 'NOT_EQUALS':
                return 'not equal to';
            case 'GREATER_THAN':
                return 'greater than';
            case 'SMALLER_THAN':
                return 'smaller than';
            default:
                return '';
        }
    };
    const getDatetimeText = (dateString: string, type: string | null = null) => {
        const fieldTypes = type ? [type] : types.value;
        if (fieldTypes.includes('datetime')) {
            return `${dayjs(dateString).utc().format('DD/MM/YYYY HH:mm')} (UTC timezone)`;
        }
        if (fieldTypes.includes('date')) {
            return `${dayjs(dateString).format('DD/MM/YYYY')}`;
        }
        if (fieldTypes.includes('time')) {
            let date = new Date(dateString);
            // eslint-disable-next-line no-restricted-globals
            if (isNaN(date.getTime())) {
                date = new Date(
                    new Date().setHours(
                        parseInt(dateString.substring(0, 2), 10),
                        parseInt(dateString.substring(3, 5), 10),
                        0,
                        0,
                    ),
                );
            }
            return `${dayjs(date).format('HH:mm')} (UTC timezone)`;
        }
        return '';
    };
    const setDateTime = (string: string) => {
        if (types.value.includes('datetime')) {
            return dayjs(string).format('YYYY-MM-DDTHH:mm:ssZ');
        }
        if (types.value.includes('date')) {
            return dayjs(string).format('YYYY-MM-DD');
        }
        if (types.value.includes('time')) {
            return dayjs(string).format('HH:mm:ssZ');
        }
        return '';
    };

    const parseTimeStringToDate = (timeString: string): Date => {
        if (isNil(timeString)) return timeString;
        const [hours, minutes] = timeString.split(':').map(Number);
        const now = new Date();
        return new Date(now.setHours(hours, minutes, 0, 0));
    };

    const getFieldValuesText = (constraint: any, fieldName: string | null) => {
        switch (constraint.multipleOperator) {
            case MultipleOperator.Element:
                return fieldName
                    ? `the value of ${fieldName} in the same array position (element) of ${constraint.parentFieldName}`
                    : `the field value in the same array position (element) of ${constraint.parentFieldName}]`;

            case MultipleOperator.Any:
                return fieldName ? `any value of ${fieldName}` : 'Any field value';

            case MultipleOperator.All:
                return fieldName ? `all (multiple) values of ${fieldName}` : 'All field values';

            default:
                return `values of ${fieldName}`;
        }
    };

    const extractFieldAndTypes = (constraint: Constraint) => {
        if (Array.isArray(constraint.fieldName)) {
            const selectedFields = fields.filter((f: any) => constraint.fieldName.includes(f.name));
            const fieldValues = selectedFields.flatMap((field) => field.path.concat(field.title).join('.')).join(' / ');
            const fieldTypes = types.value;
            const type = null;
            return { fieldValues, fieldTypes, type };
        }
        const field = fields.find((f) => f.name === constraint.fieldName);
        const fieldName = field ? field.path.concat(field.title).join('.') : null;
        const fieldValues = getFieldValuesText(constraint, fieldName);
        const fieldTypes = field ? [field.type] : types.value;
        const type = field ? field.type : null;
        return { fieldValues, fieldTypes, type };
    };

    const getRangeDetails = (constraint: Constraint, fieldTypes: string[], type: string | null) => {
        let { from, to } = constraint.details as { from: any; to: any };
        if (fieldTypes.includes('datetime') || fieldTypes.includes('date') || fieldTypes.includes('time')) {
            from = getDatetimeText(constraint.details!.from, type);
            to = getDatetimeText(constraint.details!.to, type);
        }
        return { from, to };
    };

    const getConstraintText = (constraint: Constraint, ending: string = ',') => {
        const { fieldValues, fieldTypes, type } = extractFieldAndTypes(constraint);
        const configurationText = {
            [ConstraintType.RANGE]: () => {
                const { from, to } = getRangeDetails(constraint, fieldTypes, type);
                return [`${fieldValues} are within the range`, `${from}-${to}`].concat(
                    !constraint.parentFieldName ? [`i.e. ${from} <= ${fieldValues} <= ${to}${ending}`] : [],
                );
            },
            [ConstraintType.RANGE_EXCLUDING]: () => {
                const { from, to } = getRangeDetails(constraint, fieldTypes, type);
                return [`${fieldValues} are within the range`, `${from}-${to}`].concat(
                    !constraint.parentFieldName ? [`i.e. ${from} < ${fieldValues} < ${to}${ending}`] : [],
                );
            },
            [ConstraintType.NOT_RANGE]: () => {
                const { from, to } = getRangeDetails(constraint, fieldTypes, type);
                return [`${fieldValues} are not within the range`, `${from}-${to}`].concat(
                    !constraint.parentFieldName
                        ? [`i.e. ${fieldValues} < ${from} OR ${fieldValues} > ${to}${ending}`]
                        : [],
                );
            },
            [ConstraintType.MANDATORY]: () => {
                return [`${fieldValues} are`, `null${ending}`];
            },
            [ConstraintType.UNIQUE]: () => {
                return [`${fieldValues} are`, `duplicate${ending}`];
            },
            [ConstraintType.REGULAR_EXPRESSION]: () => {
                return [
                    `${fieldValues} have an exact match with the regular expression`,
                    `${constraint.details?.regularExpression}${ending}`,
                ];
            },
            [ConstraintType.NOT_REGULAR_EXPRESSION]: () => {
                return [
                    `${fieldValues} do not have an exact match with the regular expression`,
                    `${constraint.details?.regularExpression}${ending}`,
                ];
            },
            [ConstraintType.CROSS_FIELD]: () => {
                let text = '';
                constraint.details?.conditions.forEach((condition: any) => {
                    if (condition.logicalOperator) {
                        text += ` ${condition.logicalOperator.toLowerCase()} `;
                    }
                    text += `${getConditionText(condition.conditionalOperator)} ${condition.field.replace(/__/g, '.')}`;
                });
                return [`${fieldValues} are`, `${text}${ending}`];
            },
            [ConstraintType.FOREIGN_KEY]: () => {
                return [
                    `${fieldValues} exist in values of`,
                    `${constraint.details?.field.replace(/__/g, '.')}${ending}`,
                ];
            },
            [ConstraintType.NOT_FOREIGN_KEY]: () => {
                return [
                    `${fieldValues} do not exist in values of`,
                    `${constraint.details?.field.replace(/__/g, '.')}${ending}`,
                ];
            },
        };

        return configurationText[constraint.type as string]();
    };
    const getOutliersRuleText = (constraint: any, type: string | null = null, hasStats: any = null) => {
        let { fieldValues } = extractFieldAndTypes(constraint);
        fieldValues = fieldValues.replace('values of', '');
        const fieldTypes = type ? [type] : types.value;
        const outlierText = {
            [OutliersRuleType.DEFAULT_VALUE]: () => {
                let value = constraint.outliersRule.replaceValue;
                if (fieldTypes.includes('datetime') || fieldTypes.includes('date') || fieldTypes.includes('time')) {
                    value = getDatetimeText(constraint.outliersRule.replaceValue, type);
                }
                return [`then`, `replace`, `field ${fieldValues} with value`, `${value}.`];
            },
            [OutliersRuleType.DROP]: () => {
                return ['then', 'drop row.'];
            },
            [OutliersRuleType.PREVIOUS_VALUE]: () => {
                let secondValue = constraint.outliersRule.secondaryRule.replaceValue;
                if (fieldTypes.includes('datetime') || fieldTypes.includes('date') || fieldTypes.includes('time')) {
                    secondValue = getDatetimeText(constraint.outliersRule.secondaryRule.replaceValue, type);
                }
                return [
                    `then`,
                    `replace`,
                    `field ${fieldValues} with`,
                    'its previous value',
                    'or by',
                    `${secondValue},`,
                    'if there is no previous value.',
                ];
            },
            [OutliersRuleType.MOST_FREQUENT_VALUE]: () => {
                return [`then`, `replace`, `field ${fieldValues} with the field's`, `most frequent value`];
            },
            [OutliersRuleType.MEAN_VALUE]: () => {
                return [`then replace field ${fieldValues} with the field's`, `mean value`];
            },
            [OutliersRuleType.MEDIAN_VALUE]: () => {
                return [`then`, `replace`, `field ${fieldValues} with the field's`, `median value`];
            },
            [OutliersRuleType.MIN_VALUE]: () => {
                return [`then`, `replace`, `field ${fieldValues} with the field's`, `minimum value`];
            },
            [OutliersRuleType.MAX_VALUE]: () => {
                return [`then`, `replace`, `field ${fieldValues} with the field's`, `maximum value`];
            },
            [OutliersRuleType.MOST_SIMILAR]: () => {
                return [`then`, `replace`, `field ${fieldValues} with the field's`, `most similar value`];
            },
        };
        return outlierText[constraint.outliersRule.type]();
    };

    return { getConstraintText, getOutliersRuleText, getDatetimeText, setDateTime, parseTimeStringToDate };
}
