import { useAxios, useSockets } from '@/app/composable';
import { ExecutionType, MessageType } from '@/modules/workflow-designer/constants';
import { Ref, ref } from '@vue/composition-api';
import { ApolloAPI } from '../api';
import { PreprocessingBlockId, TaskExecutionStatus, blockIdToTaskMap } from '../constants';
import { ApolloTask } from '../types';
import { useExecutionErrors } from '@/app/composable';
import { ExecutionErrorCategory } from '@/app/store/execution-errors';

export function useSampleRun(task: Ref<ApolloTask | undefined>, root: any, sampleRunCompleted: Function) {
    const { joinSocketRoom, WebSocketsRoomTypes } = useSockets();
    const { errorMessage, getExecutionError } = useExecutionErrors();

    const loadingSampleRun = ref<boolean>(false);
    const processedSample = ref<any>(null);

    const getProcessedSample = async () => {
        if (!task.value) return;
        const { exec } = useAxios();
        exec(ApolloAPI.getTask(task.value.pipeline.id, blockIdToTaskMap[task.value.blockId])).then((res: any) => {
            sampleRunCompleted(res.data.processedSample);
        });
    };

    const isCleaningAndEmptyData = (data: any) => {
        return (
            task.value?.blockId === PreprocessingBlockId.Cleaning &&
            data.body?.errors?.input_records > 0 &&
            data.body?.errors?.output_records === 0
        );
    };

    const onMessage = async (data: any) => {
        if (data.type === MessageType.Status && data.body.executionType === ExecutionType.Sample) {
            switch (data.body.status) {
                case TaskExecutionStatus.Completed:
                    await getProcessedSample();
                    loadingSampleRun.value = false;
                    break;
                case TaskExecutionStatus.Failed:
                case TaskExecutionStatus.Cancelled:
                    // TODO: Check message for empty data
                    if (root && !isCleaningAndEmptyData(data)) {
                        // Check if the error is a harvester error
                        const harvesterError = data.body.executionErrors.find((err: any) =>
                            getExecutionError(err.error_code, ExecutionErrorCategory.Harvester),
                        );

                        const message = harvesterError
                            ? `Sample run failed. ${errorMessage(harvesterError.error_code).error.description}`
                            : data.message ?? 'Sample run failed';

                        root.$toastr.e(message, 'Sample Error');
                    }
                    loadingSampleRun.value = false;
                    sampleRunCompleted(null, data.body.errors, data.body.executionErrors);
                    break;
                default:
                // do nothing
            }
        }
    };

    const executeSampleRun = async () => {
        if (!task.value) return;
        loadingSampleRun.value = true;
        joinSocketRoom(WebSocketsRoomTypes.Workflow, task.value.pipeline.id);
        const { exec } = useAxios();
        await exec(ApolloAPI.executeSampleRun(task.value.pipeline.id, blockIdToTaskMap[task.value.blockId]));
    };

    return {
        executeSampleRun,
        loadingSampleRun,
        processedSample,
        onMessage,
    };
}
