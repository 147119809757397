import { useExecutionErrors, useJsonObject, useSchedule, useSockets } from '@/app/composable';
import store from '@/app/store';
import { ScheduleAPI } from '@/modules/workflow-designer/api';
import { ScheduleType } from '@/modules/workflow-designer/types';
import { Ref, computed, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import * as R from 'ramda';
import { RetrievalType } from '../constants';
import {
    ApolloTask,
    BigQueryHarvesterConfiguration,
    HarvesterStep,
    SQLHarvesterConfiguration,
    SAPHanaHarvesterConfiguration,
} from '../types';
import { useApolloPipeline } from './apollo-pipeline';
import { useApolloTask } from './apollo-task';
import { useHarvester } from './harvester';
import { usePolicyFileUpload } from './policy-file-upload';
import { useSampleRun } from './sample-run';
import { useSQLQuery } from './sql-query';

export function useDatabaseHarvester(
    task: Ref<ApolloTask<BigQueryHarvesterConfiguration | SQLHarvesterConfiguration | SAPHanaHarvesterConfiguration>>,
    root: any,
    emit: any,
) {
    const currentStep = ref<HarvesterStep>(HarvesterStep.Setup);
    const schedules = ref<ScheduleType[]>([]);
    const showFinalizeModal = ref<boolean>(false);
    const initialRetrieval = ref<RetrievalType | undefined>(task.value.configuration.retrieval.type);
    const errorAlert = ref<{
        type: 'error' | 'warn';
        title?: string;
        description?: string;
        body?: string | null;
        expanded?: boolean;
    }>();
    const sampleRunCompleted = ref<boolean>(false);
    const finalizing = ref<boolean>(false);

    const { isValidSchedule } = useSchedule();
    const { errorMessage } = useExecutionErrors();
    const { uploadSampleFileFromData } = usePolicyFileUpload(task.value.pipeline.id);
    const { checkMissingFields, separator, basePath } = useHarvester(root, emit);
    const { getAllPaths } = useJsonObject();
    const { subscribe, unsubscribe, WebSocketsEvents, leaveSocketRoom, WebSocketsRoomTypes } = useSockets();
    const pipelineId = computed(() => task.value.pipeline.id);
    const { hasAnonymisation } = useApolloPipeline(pipelineId);
    const {
        loading: loadingTask,
        isFinalized,
        inDraftStatus,
        inUpdateStatus,
        isRunning,
        pipelineFinalized,
        hasCompleted,
        sampleRunExecuted,
        taskStructure,
        save,
        finalize,
        fetchStructure,
    } = useApolloTask(task);

    const sampleWithoutAdditional = computed(() => {
        const sample = task.value.processedSample;
        if (!sample) return [];

        if (
            finalizing.value ||
            isFinalized.value ||
            (inUpdateStatus.value && !sampleRunExecuted.value && !sampleRunCompleted.value)
        )
            for (let i = 0; i < task.value.configuration.additional.length; i++) {
                const param = task.value.configuration.additional[i];
                for (let j = 0; j < sample.length; j++)
                    if (Object.keys(sample[j]).includes(param.key)) delete sample[j][param.key];
            }

        return sample;
    });

    const initialFields = ref<string[]>(
        inUpdateStatus.value ? getAllPaths(sampleWithoutAdditional.value, '', basePath, separator) : [],
    );

    const updateProcessedSample = async (
        processedSample: any,
        errors: any[],
        executionErrors: { error_code: number; message: string }[],
    ) => {
        task.value.processedSample = processedSample ?? [];
        if (processedSample) {
            if (inUpdateStatus.value && !sampleRunExecuted.value) {
                const missingFields = checkMissingFields(processedSample, initialFields.value);
                if (missingFields)
                    errorAlert.value = {
                        type: 'warn',
                        description:
                            'Please note that there are differences in the sample data compared to the original sample data of the data check-in pipeline which you cloned. In the next steps, the mapping/cleaning/anonymisation rules will be retained only for the common fields and you may of course update them as necessary.',
                    };
            }
            await fetchStructure();
            sampleRunCompleted.value = true;
            currentStep.value += 1;
        } else {
            if (executionErrors?.length) {
                const executionError = errorMessage(executionErrors[0].error_code);
                errorAlert.value = {
                    type: 'error',
                    title: executionError.error.title,
                    description: executionError.error.description,
                    body: executionErrors[0].message,
                    expanded: false,
                };
            }
            await save(true);
        }
    };

    const { loadingSampleRun, executeSampleRun, onMessage } = useSampleRun(task, root, updateProcessedSample);

    const { queryIncludesModification, queryIncludesForbiddenTerms, parsedQuery, sqlQueryPreview } = useSQLQuery(task);

    const user = computed(() => store.state.auth.user);
    const isOwner = computed(() => task.value.pipeline?.createdById === user.value.id);

    const sampleFields = computed(() =>
        sampleWithoutAdditional.value.length === 0 ? [] : Object.keys(sampleWithoutAdditional.value[0]),
    );

    const invalidParams = computed(() =>
        task.value.configuration.additional.some((data: { key: string }) =>
            sampleFields.value.map((field: string) => field.toLowerCase()).includes(data.key.toLowerCase()),
        ),
    );

    const scheduleDetailsDefined = computed(
        () => task.value.configuration.retrieval.type === RetrievalType.Immediately || schedules.value.length > 0,
    );

    const hasChanges = computed(() => initialRetrieval.value !== task.value.configuration.retrieval.type);

    const clearProcessedSample = () => {
        task.value.processedSample = [];
    };

    const runOnSample = async () => {
        await save(true);
        errorAlert.value = undefined;
        initialRetrieval.value = task.value.configuration.retrieval.type;
        executeSampleRun();
    };

    const nextTab = async () => {
        if (task.value?.processedSample && task.value.processedSample.length === 0) runOnSample();
        else {
            if (hasChanges.value) {
                await save();
                initialRetrieval.value = task.value.configuration.retrieval.type;
            }
            currentStep.value += 1;
        }
    };

    const finalizeTask = async () => {
        try {
            finalizing.value = true;

            const finalSample = R.clone(sampleWithoutAdditional.value);

            // add additional parameters to processed sample
            if (task.value.configuration.additional.length) {
                for (let i = 0; i < task.value.configuration.additional.length; i++) {
                    const param = task.value.configuration.additional[i];
                    for (let j = 0; j < finalSample.length; j++)
                        if (!Object.keys(finalSample[j]).includes(param.key))
                            finalSample[j][param.key] = param.displayValue;
                }
            }

            task.value.configuration.sample = finalSample;

            await uploadSampleFileFromData({
                data: JSON.stringify(finalSample),
                name: 'sample.json',
                type: 'application/json',
                policy: {
                    folder: 'upload',
                    subfolder: `sample/${new Date().valueOf().toString()}`,
                },
            });
            await save();
            if (inDraftStatus.value && schedules.value.length) await ScheduleAPI.create(schedules.value);
            await finalize();
            showFinalizeModal.value = true;
        } catch {
            (root as any).$toastr.e('Failed to finalise configuration', 'An error occurred!');
        } finally {
            finalizing.value = false;
        }
    };

    const checkSchedulesAndProceed = () => {
        const invalidSchedules = schedules.value.filter(
            (schedule: ScheduleType) =>
                !isValidSchedule(schedule, task.value.configuration.retrieval.type === RetrievalType.Once),
        );

        if (!pipelineFinalized.value && invalidSchedules.length) {
            (root as any).$toastr.e(
                'One or more schedules are in the past or have no valid executions between start and end date. Please update them accordingly.',
                'Invalid Schedules!',
            );
            return;
        }

        nextTab();
    };

    if (task.value.processedSample && task.value.processedSample.length > 0) fetchStructure();

    watch(
        () => hasAnonymisation.value,
        () => {
            if (hasAnonymisation.value && task.value.configuration.retrieval.type === RetrievalType.Periodic)
                task.value.configuration.retrieval.type = RetrievalType.Immediately;
        },
        { immediate: true },
    );

    onBeforeUnmount(() => {
        unsubscribe(WebSocketsEvents.Workflow);
        leaveSocketRoom(WebSocketsRoomTypes.Workflow, task.value.pipeline?.id);
    });

    onMounted(async () => {
        subscribe(WebSocketsEvents.Workflow, (msg: any) => onMessage(msg));
    });

    return {
        isFinalized,
        currentStep,
        scheduleDetailsDefined,
        invalidParams,
        errorAlert,
        loadingTask,
        finalizing,
        isOwner,
        inDraftStatus,
        inUpdateStatus,
        isRunning,
        pipelineFinalized,
        schedules,
        loadingSampleRun,
        hasAnonymisation,
        showFinalizeModal,
        hasCompleted,
        sampleWithoutAdditional,
        sampleFields,
        taskStructure,
        queryIncludesModification,
        queryIncludesForbiddenTerms,
        parsedQuery,
        sqlQueryPreview,
        clearProcessedSample,
        runOnSample,
        nextTab,
        finalizeTask,
        checkSchedulesAndProceed,
    };
}
