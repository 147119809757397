import { useJsonObject, useModelBrowser } from '@/app/composable';
import { Concept } from '@/app/interfaces';
import { Status } from '@/modules/data-model/constants';
import { Ref, computed, ref } from '@vue/composition-api';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { InvalidMessages, ValidationError } from '../constants';
import { ApolloTask, FieldConfiguration, FieldPrediction, MappingConfiguration, Metadata, Source } from '../types';
import { useMappingPrediction } from './mapping-prediction';
import { useSampleFields } from './sample-fields';

export function useMapping(task: Ref<ApolloTask<MappingConfiguration> | undefined>) {
    let id = 1; // Auto-increment identifier for source fields. Required for mapping
    const validationErrors = ref<
        Record<number, { message: string | null; description?: string | null; type?: string; title?: string }>
    >({});

    const { extractFieldSample } = useSampleFields();
    const { getSuperObject } = useJsonObject();
    const { getConceptFromId, getConceptFromUid } = useModelBrowser();

    const configuration = computed(() => task.value?.configuration);

    // basePath has format res||<field1>||<field2>||..., so we ignore res
    const basePath = computed(() => configuration.value?.basePath?.split('||')?.slice(1));

    const { predict } = useMappingPrediction(configuration, basePath);

    const sample = computed(() => task.value?.inputSample ?? []);

    const rootConcept = computed(() =>
        configuration.value?.concept?.id ? getConceptFromId(configuration.value.concept.id) : undefined,
    );

    const superSample = computed(() => getSuperObject(R.clone(sample.value), []));

    const checkType = (value: any): string => {
        if (isNaN(value) && !R.is(Array, value) && dayjs(Date.parse(value)).isValid()) return 'Date';
        return R.type(value); // Booleans are considered numbers, thus we need to account for that as well
    };

    const fieldType = (data: Record<string, any>[]) => {
        const types = R.uniq(R.map(checkType, data));
        if (R.contains('Array', types)) return 'array';
        if (R.contains('Object', types)) return 'object';
        if (R.contains('String', types)) return 'string';
        if (R.contains('Date', types)) return 'date';
        if (R.contains('Number', types)) return 'number';
        if (R.contains('Boolean', types)) return 'boolean';
        if (types.length === 1 && types[0] === 'Null') return 'null';
        return '';
    };

    /**
     * Recursive function to extract all fields from the given sample
     * @param title The title of the field
     * @param path The path of the field
     */
    const extractFields = (title: string, path: string[], result: Source[]) => {
        const fieldSample = extractFieldSample(superSample.value, title, path);
        const type = title === '_uploaded_file' ? 'base64binary' : fieldType(fieldSample);
        const notEmptySample = fieldSample.find((fs: any) => fs);

        if (type === 'array') {
            if (fieldSample.length > 0) {
                let fieldSamples: any[] = [];
                fieldSample.forEach((fs) => {
                    fieldSamples = fieldSamples.concat(fs);
                });
                const arrayType = fieldType(fieldSamples);
                if (['string', 'number', 'date', 'boolean'].includes(arrayType)) {
                    if (!result.find((item) => R.equals(item.title, title) && R.equals(item.path, path)))
                        result.push({ id: id++, title, path, type: `array [${arrayType}]` });
                    return;
                } else if (arrayType === 'object') {
                    if (notEmptySample) {
                        const field = notEmptySample.find((fs: any) => fs);
                        if (field) {
                            Object.keys(field).forEach((key: string) =>
                                extractFields(key, [...path, `${title}[]`], result),
                            );
                            return;
                        }
                    }
                } else if (arrayType === 'array') {
                    const pathArray: any[] = [...path, title];
                    let nestedArrayCount = 0;
                    let finalTitle = title;

                    // build nested array structure using the innermost object nesting level
                    // e.g. superSample = { value: [{ field1: { title: [[{ key: 'value' }]]} }] }
                    // e.g. pathArray = ['field1', 'title'] => final path array will be ['field1', 'title', 0, 0]
                    // e.g. finalTitle = 'title[][]'
                    while (R.is(Array, R.map(R.path(pathArray), superSample.value)[0])) {
                        pathArray.push(0);
                        finalTitle += '[]';
                        nestedArrayCount += 1;
                    }

                    notEmptySample.forEach((obj: any) => {
                        const field = obj.flat(nestedArrayCount).find((fs: any) => fs);
                        if (field) {
                            Object.keys(field).forEach((key: string) =>
                                extractFields(key, [...path, finalTitle], result),
                            );
                        }
                    });
                    return;
                }
            }
            return;
        }

        if (type === 'object' && notEmptySample) {
            Object.keys(notEmptySample).forEach((key: string) => extractFields(key, [...path, title], result));
            return;
        }

        if (!result.find((item) => R.equals(item.title, title) && R.equals(item.path, path)))
            result.push({ id: id++, title, path, type });

        return;
    };

    /**
     * Returns a blank field
     * @param source
     */
    const initEmptyField = (source: Source): FieldConfiguration => {
        if (!rootConcept.value) throw Error('Root concept not defined!');
        return {
            source,
            target: {
                id: null,
                uid: null,
                title: null,
                parentIds: [rootConcept.value.id],
                type: null,
                path: [rootConcept.value.name],
                categories: [rootConcept.value.name],
                pathUids: [rootConcept.value.uid],
            },
            prediction: undefined,
            temp: { invalid: false },
        };
    };

    /**
     * Extracts the value of a field given the path
     *
     * @param keepSelections Whether to return existing value
     * @param field - The field to query
     * @param path - The path of interest
     * @param defaultValue - The default value to return if we are not
     * interested in the existing value of the field
     */
    const extractFieldValue = (
        keepSelections: boolean,
        field: FieldConfiguration,
        path: string[],
        defaultValue: any = null,
    ) => {
        return !keepSelections ? defaultValue : R.path(path, field);
    };

    const createFieldConfiguration = (
        field: FieldConfiguration,
        concept: Concept,
        prediction: any,
        keepSelections = false,
        defaultOrder: any = null,
    ): FieldConfiguration => {
        let newField = {
            source: field.source,
            target: {
                ...field.target,
                uid: concept.uid,
                id: concept.id,
                title: concept.name,
                type: concept.type,
            },
            transformation: {},
            metadata: R.pick(['indexES', 'indexMongo', 'temporal', 'spatial'], concept.metadata) as Metadata,
            temp: { ...field.temp, userDefined: prediction === null },
            annotation: null,
            alias: null,
        };

        if (prediction) newField = R.assocPath(['prediction'], prediction, newField);
        else if (R.hasPath(['prediction'], newField))
            newField = R.assocPath(['prediction'], R.clone(field.prediction), newField);

        // Date transformations
        if (concept.type === 'datetime') {
            newField = R.assocPath(
                ['transformation', 'sourceTimezone'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceTimezone']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'sourceDateFormat'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceDateFormat']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'dateOrder'],
                extractFieldValue(keepSelections, newField, ['transformation', 'dateOrder']),
                newField,
            );
        }

        if (concept.type === 'date') {
            newField = R.assocPath(
                ['transformation', 'sourceDateFormat'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceDateFormat']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'dateOrder'],
                extractFieldValue(keepSelections, newField, ['transformation', 'dateOrder']),
                newField,
            );
        }

        if (concept.type === 'time') {
            newField = R.assocPath(
                ['transformation', 'sourceTimezone'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceTimezone']),
                newField,
            );
        }

        if (concept.type === 'double') {
            newField = R.assocPath(['transformation', 'thousandsSeperator'], '', newField);
            newField = R.assocPath(['transformation', 'decimalPoint'], '.', newField);
        }

        // More arrays in target path than source path
        const tempField = removeArrayBracketsFromBasePath(field);
        const arraysInSource = tempField.source.path.join('')?.match(/\[\]/g)?.length ?? 0;
        const arraysInTarget = tempField.target.path.join('')?.match(/\[\]/g)?.length ?? 0;

        if (tempField.source.path.some((p) => p.includes('[]')) && arraysInTarget > arraysInSource)
            newField = R.assocPath(['transformation', 'oneElementArrays'], [], newField);

        // Multiple (and ordered) fields
        if (concept.metadata?.multiple) {
            newField = R.assocPath(
                ['transformation', 'multiple'],
                extractFieldValue(keepSelections, newField, ['transformation', 'multiple'], true),
                newField,
            );
            if (concept.metadata.ordered)
                newField = R.assocPath(
                    ['transformation', 'order'],
                    extractFieldValue(keepSelections, newField, ['transformation', 'order'], defaultOrder),
                    newField,
                );
        }

        // Unit Transformations
        if (concept.metadata?.measurementType) {
            newField = R.assocPath(
                ['transformation', 'measurementType'],
                extractFieldValue(
                    keepSelections,
                    newField,
                    ['transformation', 'measurementType'],
                    concept.metadata.measurementType,
                ),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'sourceUnit'],
                extractFieldValue(keepSelections, newField, ['transformation', 'sourceUnit']),
                newField,
            );
            newField = R.assocPath(
                ['transformation', 'targetUnit'],
                extractFieldValue(
                    keepSelections,
                    newField,
                    ['transformation', 'targetUnit'],
                    concept.metadata.measurementUnit,
                ),
                newField,
            );
        }

        return newField;
    };

    const setConcept = (field: FieldConfiguration, concept: Concept, prediction: any = null) => {
        // If concept is multiple find how many other concepts exist with the same target id and path
        let sameMultipleFields = 0;
        if (concept?.metadata?.multiple) {
            const conceptId = concept?.id;
            const targetPath = field.target?.path;
            if (configuration.value)
                sameMultipleFields = configuration.value.fields.reduce((count: number, f: any) => {
                    return f.target.id === conceptId && R.equals(f.target.path, targetPath) ? count + 1 : count;
                }, 0);
        }

        if (configuration.value) {
            const idx = configuration.value.fields.findIndex(
                (f: FieldConfiguration) => f.source.id === field.source.id,
            );
            if (~idx)
                configuration.value.fields.splice(
                    idx,
                    1,
                    createFieldConfiguration(field, concept, prediction, false, sameMultipleFields + 1),
                );
        }
    };

    const initPredictMapping = async (fields: FieldConfiguration[], targetIds: number[] = []) => {
        const conceptId =
            rootConcept.value && rootConcept.value.referenceId
                ? rootConcept.value.referenceId
                : configuration.value?.concept?.id;
        const domainId = configuration.value?.concept?.id
            ? getConceptFromId(configuration.value.concept.id)?.domain
            : undefined;
        const predictionResponse: any = await predict(fields, conceptId, domainId, targetIds);

        for (let idx = 0; idx < fields.length; idx++) {
            const field: FieldConfiguration = fields[idx];
            const fieldPrediction: FieldPrediction | null = predictionResponse[field.source.id];

            if (fieldPrediction && fieldPrediction.matchings) {
                const concept = getConceptFromId(fieldPrediction.matchings.target);
                const prediction = fieldPrediction.matchings;
                setConcept(field, concept, prediction);
            }
        }
    };

    const refreshMapping = async () => {
        if (!configuration.value?.fields || sample.value.length === 0) return;

        const keys = Object.keys(sample.value[0]);
        const result: Source[] = [];
        keys.forEach((key: string) => extractFields(key, [], result));
        const initFields: FieldConfiguration[] = [];
        const targetIds: number[] = [];

        configuration.value.fields = result.map((source) => {
            let field = configuration.value?.fields.find(
                (f: FieldConfiguration) => f.source.title === source.title && R.equals(f.source.path, source.path),
            );
            if (field) {
                if (field.transformation) {
                    // remove array brackets from source path based on base path
                    const tempField = removeArrayBracketsFromBasePath(field);
                    const arraysInSource = tempField.source.path.join('')?.match(/\[\]/g)?.length ?? 0;
                    const arraysInTarget = tempField.target.path.join('')?.match(/\[\]/g)?.length ?? 0;

                    // if source path contains arrays and target path has more arrays than source path
                    if (tempField.source.path.some((p) => p.includes('[]')) && arraysInTarget > arraysInSource) {
                        // add oneElementArrays transformation if it does not exist
                        if (!R.has('oneElementArrays', field.transformation))
                            field = R.assocPath(['transformation', 'oneElementArrays'], [], field);
                    }
                    // otherwise remove oneElementArrays transformation
                    else field.transformation = R.omit(['oneElementArrays'], field.transformation);
                }

                if (field.target.id) targetIds.push(field.target.id);
                return field;
            }
            const newField = initEmptyField(source);
            initFields.push(newField);
            return newField;
        });

        // make sure source ids are in sequence in case new fields are initiated when refreshing mapping
        // since backend updates the source ids sequence, in case of adding new fields for mapping,
        // the source ids might already exist but for different fields and if source ids are not in sequence
        // for prediction, will cause duplicate fields and delete fields with the same id
        configuration.value.fields.forEach((field: FieldConfiguration, idx: number) => {
            field.source.id = idx + 1;
        });

        if (!initFields.length) return;
        await initPredictMapping(initFields, targetIds);
    };

    const removeArrayBracketsFromBasePath = (field: FieldConfiguration): FieldConfiguration => {
        if (!basePath?.value || field.source.path.length < basePath.value.length) return field;
        const newField = R.clone(field);
        for (let i = 0; i < basePath.value.length; i++)
            if (newField.source.path[i].replaceAll('[]', '') === basePath.value[i])
                newField.source.path[i] = newField.source.path[i].replaceAll('[]', '');
            else break;
        return newField;
    };

    const makeFieldInvalid = (
        field: FieldConfiguration,
        obj: { message: string; description?: string },
        invalidRegex = false,
    ) => {
        field.temp.invalid = true;
        if (invalidRegex) field.temp.invalidRegex = true;
        validationErrors.value[field.source.id].message = obj.message;
        if (obj.description) validationErrors.value[field.source.id].description = obj.description;
    };

    // returns true if there is circular nesting in a given path
    // for example, input: ["a", "b", "c", "b", "e"] will return true
    const hasCircularNesting = (inputValues: string[]) => {
        let isCircular = false;
        for (let i = 0; i < inputValues.length; i++) {
            for (let j = 0; j < inputValues.length; j++) {
                if (j >= i) break;
                if (j < i && inputValues[i] === inputValues[j]) {
                    isCircular = true;
                    break;
                }
            }
            if (isCircular) break;
        }
        return isCircular;
    };

    const validate = () => {
        if (!configuration.value) return;

        let isValid = true;

        for (let i = 0; i < configuration.value.fields.length; i++) {
            const field = removeArrayBracketsFromBasePath(configuration.value.fields[i]);

            if (field.temp.invalid && validationErrors.value[field.source.id]?.type === 'sample') {
                isValid = false;
                continue;
            }

            field.temp.invalid = false;

            if (field.target.id === null) continue; // Not testing for empty targets

            validationErrors.value[field.source.id] = { message: null, description: null };

            // Calculate duplicates, to be used below
            const duplicates = configuration.value.fields.filter(
                (obj) =>
                    obj.target.id &&
                    obj.target.id === field.target.id &&
                    obj.target.path.join('__') === field.target.path.join('__'),
            );

            // Calculate duplicate alias names
            const duplicateAliases: FieldConfiguration[] = configuration.value?.fields.filter(
                (obj) =>
                    obj.target.id &&
                    obj.alias?.length &&
                    field.alias?.length &&
                    obj.source.id !== field.source.id &&
                    obj.alias === field.alias,
            );

            const arraysInSource = field.source.path.join('')?.match(/\[\]/g)?.length ?? 0;
            const arraysInTarget = field.target.path.join('')?.match(/\[\]/g)?.length ?? 0;

            if (field.transformation) {
                // Missing unit transformation
                if (
                    field.transformation.measurementType &&
                    field.transformation.measurementType !== 'Not relevant' &&
                    field.transformation.sourceUnit === null
                )
                    makeFieldInvalid(field, InvalidMessages[ValidationError.MissingMeasurementUnit]);

                // Missing date format
                if (field.transformation.sourceDateFormat === null)
                    makeFieldInvalid(field, InvalidMessages[ValidationError.MissingDateFormat]);

                // Missing timezone
                if (field.transformation.sourceTimezone === null)
                    makeFieldInvalid(field, InvalidMessages[ValidationError.MissingTimezone]);

                // Missing date order
                if (field.transformation.sourceDateFormat === 'infer' && field.transformation.dateOrder === null)
                    makeFieldInvalid(field, InvalidMessages[ValidationError.MissingDateOrder]);

                // More arrays in target path than source path
                if (
                    R.has('oneElementArrays', field.transformation) &&
                    arraysInTarget - arraysInSource !== field.transformation.oneElementArrays?.length
                )
                    makeFieldInvalid(field, InvalidMessages[ValidationError.LongerNestingLevel]);
            }

            // Check if is array and not a multiple field
            if (/\[.*?\]/.test(field.source.type) && !field.transformation?.multiple)
                makeFieldInvalid(field, InvalidMessages[ValidationError.Multiple]);

            // Check for same level of nesting with arrays
            if (arraysInSource > arraysInTarget)
                makeFieldInvalid(field, InvalidMessages[ValidationError.ShorterNestingLevel]);

            if (field.transformation?.multiple && field.source.type.startsWith('array [') && duplicates.length > 1)
                makeFieldInvalid(field, InvalidMessages[ValidationError.Array]);

            if (field.transformation && R.has('order', field.transformation)) {
                if (field.transformation.order === null)
                    makeFieldInvalid(field, InvalidMessages[ValidationError.MissingOrder]);
                else if (
                    duplicates.filter((obj) => obj.transformation?.order === field.transformation?.order).length > 1
                )
                    makeFieldInvalid(field, InvalidMessages[ValidationError.DuplicateOrder]);
            } else if (duplicates.length > 1 && !field.transformation?.multiple)
                makeFieldInvalid(field, InvalidMessages[ValidationError.DuplicateField]);

            if (duplicateAliases.length && !field.temp.invalid)
                makeFieldInvalid(field, InvalidMessages[ValidationError.DuplicateAlias]);

            if (hasCircularNesting(field.target.categories))
                makeFieldInvalid(field, InvalidMessages[ValidationError.CircularReference]);

            if (field.alias?.length && !/^[A-Za-z0-9-_]*$/.test(field.alias))
                makeFieldInvalid(field, InvalidMessages[ValidationError.AliasFormat], true);

            configuration.value.fields[i].temp.invalid = field.temp.invalid;
            isValid = isValid && !field.temp.invalid;
        }

        return isValid;
    };

    /**
     * Migrates an unfinished mapping to a newer version of the model
     *
     * @param domain - The mapped model
     * @param concept - The mapped concept
     * @param fields - The mapped fields
     * @param idMappings - Mappings from old ids to new concepts
     * @param mainConceptDeprecated - Whether the parent concept is deprecated or not
     */
    const migrate = (
        domain: any,
        concept: any,
        fields: FieldConfiguration[],
        idMappings: any,
        mainConceptDeprecated = false,
    ): { fields: any[]; domain: any; concept: any; deprecatedFields: string[] } => {
        // reconstructing the mapped fields
        const mappedFields = [];
        const deprecatedFields: string[] = [];
        for (let idx = 0; idx < fields.length; idx++) {
            const field: FieldConfiguration = fields[idx];

            // if the parent is not deprecated and we have a target id
            // then we recreate the field with the new concept object
            if (!mainConceptDeprecated) {
                if (field.target.id && field.target.title) {
                    const newConcept = idMappings[field.target.id];
                    if (newConcept && newConcept.status !== Status.Deprecated) {
                        // if concept exists and is not deprecated then recreate clean configuration
                        mappedFields.push(createFieldConfiguration(field, newConcept, field.prediction, true));
                    } else {
                        // otherwise clean it up
                        deprecatedFields.push(field.target.title);
                        mappedFields.push(initEmptyField(field.source));
                    }
                } else {
                    // otherwise we store a blank field
                    mappedFields.push(initEmptyField(field.source));
                }
            }
        }

        let newDomain = R.clone(domain);
        let newConcept = R.clone(concept);
        // replace old fields with new ones
        if (!mainConceptDeprecated) {
            // if concept is not deprecated
            // replace the domain and concept information
            newDomain.name = idMappings[domain.id].name;
            newDomain.id = idMappings[domain.id].id;
            newDomain.uid = idMappings[domain.id].uid;
            newDomain.majorVersion = idMappings[domain.id].majorVersion;
            newConcept.name = idMappings[concept.id].name;
            newConcept.id = idMappings[concept.id].id;
            newConcept.uid = idMappings[concept.id].uid;
        } else {
            newDomain = null;
            newConcept = null;
        }

        return {
            fields: mappedFields,
            domain: newDomain,
            concept: newConcept,
            deprecatedFields,
        };
    };

    const resetMapping = async (initPredict = true) => {
        if (!task.value) return;
        const keys = sample.value.length ? Object.keys(sample.value[0]) : [];
        const result: Source[] = [];
        keys.forEach((key: string) => extractFields(key, [], result));
        task.value.configuration.fields = result.reduce((fields: FieldConfiguration[], source: Source) => {
            fields.push(initEmptyField(source));
            return fields;
        }, []);
        if (initPredict) await initPredictMapping(task.value.configuration.fields);
    };

    const getValidConfiguration = (conf: MappingConfiguration) => {
        const config = R.clone(conf);
        return {
            ...config,
            fields: config.fields.map((field: FieldConfiguration) => {
                const pathUids = [];
                const path = [];
                const parentIds = [];
                const categories = [];

                for (let p = 0; p < field.target.pathUids.length; p++) {
                    const pathUid = field.target.pathUids[p];
                    if (getConceptFromUid(pathUid)) {
                        pathUids.push(pathUid);
                        path.push(field.target.path[p]);
                        parentIds.push(field.target.parentIds[p]);
                        categories.push(field.target.categories[p]);
                    } else break;
                }

                if (pathUids.length === 0 && field.target.pathUids.length > 0 && rootConcept.value)
                    return initEmptyField(field.source);
                else if (pathUids.length !== field.target.pathUids.length)
                    return {
                        ...field,
                        target: {
                            ...field.target,
                            uid: null,
                            id: null,
                            title: null,
                            type: null,
                            pathUids,
                            path,
                            parentIds,
                            categories,
                        },
                    };
                else if (field.target.id && !getConceptFromId(field.target.id))
                    return {
                        ...field,
                        target: { ...field.target, uid: null, id: null, title: null, type: null },
                    };
                return field;
            }),
        };
    };

    return {
        configuration,
        basePath,
        sample,
        validationErrors,
        rootConcept,
        validate,
        refreshMapping,
        resetMapping,
        setConcept,
        migrate,
        initEmptyField,
        getValidConfiguration,
        initPredictMapping,
    };
}
